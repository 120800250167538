export const STYLE_ENV_FIELD_NAME = "style";
export const createPropsFilter = (allowedProps = []) => allowedProps === false
    ? (props) => props
    : function filterProps(props) {
        const filteredProps = {};
        allowedProps
            .filter((key) => props[key])
            .forEach((key) => {
            filteredProps[key] = props[key];
        });
        return filteredProps;
    };
