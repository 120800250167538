import { ensureNonNullable } from "@neonaut/lib-js/nonNullable";
import LRUCache from "ol/structs/LRUCache";
import deriveGeometriesFromBase from "../geometry/deriveGeometriesFromBase";
import declarationToStyle from "./declarationToStyle";
import declarationToGeometry from "./declarationToGeometry";
import { createPropsFilter, STYLE_ENV_FIELD_NAME, } from "./styleFunction";
const TYPE_GEOMETRY_COLLECTION = "GeometryCollection";
const DEFAULT_STYLE = "default";
const HASH_STRING_DELIMITER = "|";
const DEFAULT_ROOT_STYLE_TYPE = "style";
const DEFAULT_CACHE_LEVEL_1_SIZE = 100;
const DEFAULT_CACHE_LEVEL_2_SIZE = 100;
const DEFAULT_CACHE_LEVEL_3_SIZE = 100;
/* eslint-disable jsdoc/check-param-names */
/**
 * Creates a cached mapsight style function for openlayers
 *
 * @param constructorsMap map of style constructors
 * @param declarationHashFunction style declaration hash function
 * @param declarationFunction style declaration function
 * @param [allowedProps=false] list of props allowed, false = all allowed
 * @param [allowedStyles=false] list of styles allowed, false = all allowed
 * @param [cacheLevel1Size=100] size of first level cache that caches feature geometry styles based on feature and environment state
 * @param [cacheLevel2Size=100] size of second level cache that caches style objects based on the rules that apply and the environment state
 * @param [cacheLevel3Size=100] size of third level cache that caches style objects based on the rules that apply and the environment state
 *
 * @returns style function
 */
export default function createCachedStyleFunction({ constructorsMap, declarationHashFunction, declarationFunction, allowedProps = false, allowedStyles = false, cacheLevel1Size = DEFAULT_CACHE_LEVEL_1_SIZE, cacheLevel2Size = DEFAULT_CACHE_LEVEL_2_SIZE, cacheLevel3Size = DEFAULT_CACHE_LEVEL_3_SIZE, }) {
    const filterProps = createPropsFilter(allowedProps);
    const cacheLevel1 = new LRUCache(cacheLevel1Size); // the first level cache caches feature geometry styles based on feature and environment state
    const cacheLevel2 = new LRUCache(cacheLevel2Size); // the second level cache caches style objects based on the rules that apply and the environment state
    const cacheLevel3 = new LRUCache(cacheLevel3Size); // the third level cache caches style objects based on the rules that apply and the environment state
    function level1(env, props, envHash, propsHash, geometryType) {
        const cacheHashL1 = envHash +
            HASH_STRING_DELIMITER +
            geometryType +
            HASH_STRING_DELIMITER +
            propsHash;
        if (!cacheLevel1.containsKey(cacheHashL1)) {
            cacheLevel1.set(cacheHashL1, level2(env, props, envHash, propsHash, geometryType));
        }
        return cacheLevel1.get(cacheHashL1);
    }
    function level2(env, props, envHash, propsHash, geometryType) {
        let styleName = DEFAULT_STYLE;
        const envStyle = env[STYLE_ENV_FIELD_NAME];
        if (typeof envStyle === "string") {
            if (allowedStyles === false) {
                styleName = envStyle;
            }
            else {
                styleName =
                    allowedStyles.indexOf(envStyle) > -1
                        ? envStyle
                        : DEFAULT_STYLE;
            }
        }
        const cacheHashL2 = declarationHashFunction(env, props, envHash, geometryType, styleName);
        if (cacheLevel2.containsKey(cacheHashL2)) {
            return cacheLevel2.get(cacheHashL2);
        }
        const declarations = declarationFunction(env, props, geometryType, styleName); // Having to cast here because of the tsc bug noted above
        const values = Object.keys(declarations).map((group) => ({
            style: declarationToStyle(constructorsMap, ensureNonNullable(declarations[group]), DEFAULT_ROOT_STYLE_TYPE, group + "," + cacheHashL2, cacheLevel3),
            geometryTypeOrDerivation: declarationToGeometry(declarations[group]),
        }));
        cacheLevel2.set(cacheHashL2, values);
        return values;
    }
    function styleGeometryOrGeometryCollectionCached(env, props, envHash, propsHash, geometryOrGeometryCollection, i = 0) {
        if (!geometryOrGeometryCollection) {
            return [];
        }
        const geometryType = geometryOrGeometryCollection.getType();
        // recurse through collections
        if (geometryType === TYPE_GEOMETRY_COLLECTION) {
            const collection = geometryOrGeometryCollection;
            return collection
                .getGeometries()
                .flatMap((geometry) => styleGeometryOrGeometryCollectionCached(env, props, envHash, propsHash, geometry, i + 1));
        }
        return level1(env, props, envHash, propsHash, geometryType)
            .filter(({ style }) => style != null)
            .flatMap(function bindStyleToBaseGeometry({ style: baseStyle, geometryTypeOrDerivation: geometryDerivation, }) {
            if (baseStyle === null) {
                return [];
            }
            const derivedGeometries = deriveGeometriesFromBase(geometryOrGeometryCollection, geometryDerivation);
            return derivedGeometries.map(function bindDerivedGeometryToStyle(geometryWithMeta) {
                const [geometry, meta] = geometryWithMeta;
                const style = baseStyle.clone();
                style.setGeometry(geometry);
                if (meta?.rotation != null && style.getImage()) {
                    const imageStyle = style.getImage().clone();
                    const baseRotation = imageStyle.getRotation();
                    imageStyle.setRotation(baseRotation + meta.rotation);
                    style.setImage(imageStyle);
                }
                return style;
            });
        });
    }
    const cachedStyleFunction = (env = {}, feature) => {
        // subset of feature properties that are relevant for styling (also used for caching decisions)
        const filteredProps = filterProps(feature.getProperties());
        return styleGeometryOrGeometryCollectionCached(env, filteredProps, JSON.stringify(env), JSON.stringify(filteredProps), feature.getGeometry());
    };
    return cachedStyleFunction;
}
